body {
  font-family: 'Raleway', sans-serif !important;
}

.jumbotron {
  min-height: 300px;
  background: #17234E url("images/banner-ligh-smal-300H.jpeg") no-repeat center;
  color: black;
  font-weight: bold;

  h3 {
    margin-top: 200px;
    float: right;
    color: white;
    text-align: right;
  }
}

.languages {
  float: right;
}

h1, h2 {
  color: lightskyblue;
}

.warning {
  color: darkred;
  font-weight: bolder;
}
